@font-face {
  font-family: "Louis George Cafe";
  src: url("./fonts");
  src: url("./fonts/Louis George Cafe.ttf") format("ttf"),
    url("./fonts/Louis George Cafe.ttf") format("truetype");
}

@font-face {
  font-family: "nunito";
  src: url("./fonts");
  src: url("./fonts/Nunito-VariableFont_wght.ttf") format("ttf"),
    url("./fonts/Nunito-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "poppins";
  src: url("./fonts");
  src: url("./fonts/Poppins-Regular.ttf") format("ttf"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}


body {
  font-family: "nunito";
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  transition: 0.6s;
  text-decoration: underline;
  text-decoration-color: transparent;
}


a {
  color: black;
  font-family: "poppins";
  text-decoration: none;
  text-decoration: transparent;
  -webkit-transition: .6s ease-out;
  -moz-transition: .6s ease-out;
  -o-transition: .6s ease-out;
  transition: .6s ease-out;

  &:hover {
    text-decoration: black;
    text-decoration: underline;
  }
}



a:hover {
  color: black;

}

h2 {
  text-transform: uppercase;
}
h1,
h3 {
  font-size: 1.875rem;
  font-family: "nunito";
}
p,
span {
  font-size: 0.875rem;
}

.btn {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "poppins";
  position: relative;
  font-size: 12px;
  letter-spacing: 1px;
  min-width: 200px;
  text-align: center;
  border: 0px;
  transition: .6s;
  line-height: 43px;
  height: 47px;
  padding: 0 29px 0px 25px;

  span {
    font-size: 12px;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  img {
    position: absolute;
    width: calc(100% + 4px);
    height: 40px;
    top: 0px;
    left: 0px;
  }
}

.button-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;


  &:hover {


    transition: .6s;
    text-decoration: none;
  }
}

.btn-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('../img/Dowiedz_sie_wiecej_button_in_color.jpg');
    // transition: .6s;
    // scale: 1.01;
    // color: $secondary-gray;
    // transition: .6s;
    // text-decoration: none;
}

button.btn img {
  height: 43px;
}

.languages {
  font-size: 14px;
  border-color: black !important;
}

.border-top {
  border-top: 1px solid #000 !important;
}

.component {
  display: inline-block;
  padding: 0 2px;

  &:first-child {
    padding-left: 0;
  }

  a {
    text-decoration: none;
  }

  img {
    width: 24px;
    height: 24px;
    // display: inline-block;
  }
}



header {
  position: fixed;
  z-index: 100;
  background: white;
  width: 100vw;
}

.nav {
  padding: 0;
}

.navbar {
  height: $navbar-height;
  background-color: white !important;
  padding: 0;

  >div {
    height: 100%;
  }

  .navbar-links-wrapper {
    display: flex;
    justify-content: center;

    ul {
      display: inline-block;
      margin-right: 7.1%;
      margin-left: auto;
      // margin: 0% % 0% auto;
    }
  }

  li {
    display: inline-block;
    text-transform: uppercase;
    padding: 0 10px;

    a {
      text-transform: uppercase;
      transition:0.6s;
      font-weight: 700;
      &:hover,
      &.active{
        text-decoration:underline;
      }
    }
  }

  .navbar-components {
    padding-right: 0px;
  }

  .row {
    width: 100%;
  }

  .navbar-collapse {
    position: absolute;
    top: $navbar-height;
    width: 100vw;
    left: 0;
    background-color: $navbar-bg;
  }

  .navbar-toggler {
    padding: 0;
    border: none;
    position: relative;
    top: 1px;

    &:focus {
      text-decoration: none;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    span {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      display: inline-block;
      width: 28px;
      height: 28px;
      vertical-align: middle;
      content: "";
      background-size: auto;
      background-size: 100% 100%;
    }
  }

  .brand {
    height: 100%;

  }
}
.prev-modal{
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
.next-modal{
  padding: 5px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.slick .slick-next {
  right: 10px;

  &:hover {
    right: 3px;
  }
}

.slick .slick-prev {
  left: 10px;

  &:hover {
    left: 3px;
  }
}

// nav end
// banner top
.banner-top {
  height: 65vh;
  top: $navbar-height;
  z-index: 0;
  padding: 0%;

  .banner-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    text-align: center;
  }

  h1 {
    z-index: 1;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4.4rem;
    color: white;
    text-shadow: 0px 0px 35px #000, 0px 0px 35px #000;
  }

  .flex-control-paging li a {
    box-shadow: none;
    width: 17px;
    height: 17px;
    border: 5px solid white;
    background: none;
  }

  .flex-control-nav {
    bottom: 8%;
    z-index: 2;
  }

  .flex-control-paging li a.flex-active {
    background: white;
  }

  .flexslider {
    height: 100%;
    overflow: hidden;
    border: none;
  }

  .slides {
    height: 100%;
  }

  li {
    height: 100%;
  }

  .slide-img {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 100;
    top: 0;
    video{
      width:100%;
      height:100%;
      object-fit: cover;
    }
    .mobile{
      display:none;
    }
  }
}

// end banner-top
// sections h2
//about
.about {
  margin-top: $navbar-height;
}

section {
  padding: 60px 0px;
}

.div-h2 {
  margin-bottom: 50px;

  h2 {
    border-bottom: 1px solid #F90101;
    padding-bottom: 5px;
    font-weight: 700;
  }
}
h1,
h3 {
  padding-bottom: 20px;
}

.youtube-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.statistics {
  padding-top: 50px;

  .statistics-img {
    width: 50%;
  }

  div {
    text-align: center;
  }

  .description {
    padding-top: 24px;
    font-size: 24px;
  }
}

.numbers {
  font-size: 3.2rem;
  line-height: 31px;
  color: $secondary-color;
  font-family: "Louis George Cafe";
  span{
    font-size: inherit;
  }
}

.logo-side-wrapper {

  div {
    display: flex;
    align-items: center;
    justify-content: center;

  }
}

.logos-index {
  .logos {

    justify-content: center;
  }

  div {
    display: flex;
    align-items: center;
    padding: 20px 20px;
  }
}

// .logos {
//     display: flex;
//     flex-wrap: nowrap;

//     div {
//         flex-shrink: 1;
//     }
// }

// footer
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  height: 100%;


  a {
    height: 100%;
  }

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: auto;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next {
  display: none;
}

.swiper-button-prev {
  display: none;
}

.checked.swiper {
  .swiper-slide {
    margin-top: auto;
    margin-bottom: 0;
  }
}

footer {
  background-color: $secondary-gray;

  h5 {
    font-size: 20px;
  }

  li {
    text-transform: uppercase;
    font-size: 14px;
    color: #000;
    font-family: 'poppins';
    margin-bottom: 3.5px;

    a {
      font-size: 14px;
    }
  }
}

.social {
  .component {
    display: inline-block;
  }
}

// secondary-banner
.sec-banner {
  position: relative;
  top: $navbar-height;
  display: flex;
  align-items: center;
  margin-bottom: 70px;
  overflow: hidden;
  height: 65vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;


  .sec-banner-wrapper {
    background: linear-gradient(90deg, rgb(255, 255, 255), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.05));
    min-height: 70%;
    display: flex;
    align-items: center;

    .row {
      align-items: center;
      height: 100%;
      width: 100%;
    }

    h2 {
      padding-bottom: 20px;
    }
  }
}

.sec-banner-text {
  line-height: 16px;
  width: 85%;
}

// about

.about-statistics {
  .col {
    padding: 10px 0;
  }



  .numbers {
    line-height: 55px;
    margin-bottom: 8px;
  }
}

.statistics-description {
  align-content: center;
  display: grid;

  .description {
    font-size: 20px;
  }
}

.map {
  text-align: center;
}

.img-statistics {
  position: relative;
  bottom: 5px;
}

// our fruits
.table-responsive {
  padding-bottom: 20px;
  height: 320px;
  overflow-Y: hidden;
  z-index: 1;
  transition: .5s ease-in;
  width: 100%;

}

.all-fruits {
  .apple-img {
    img {
      width: 50%;
      max-width: 150px;
    }
  }

  h3 {
    text-transform: uppercase;
    padding-bottom: 0%;
    margin-bottom: 0%;
    min-height: 72px;
  }
}


table {
  z-index: 0;
  width: 100%;
}

tr {
  height: 60px;
}

tr:first-of-type {
  height: 80px;
}

td {
  width: 90px;
  text-align: center;
  border-bottom: 3px solid #d3d3d3;
  min-height: 60px;
}

th {
  height: 30px;
  text-transform: uppercase;
  font-size: 0.75rem;
  min-width: 90px;
}

td:nth-child(2n) {
  background-color: $td-2n;
}

th:nth-child(2n) {
  background-color: $th-2n;
}

td:nth-child(2n + 1) {
  background-color: $td-2n1;
}

th:nth-child(2n + 1) {
  background-color: $th-2n1;
}

th:first-of-type {
  background-color: $th-first;
  min-width: 136px;
  font-weight: bold;

}

td:first-of-type {
  background-color: $td;
  font-weight: bold;
  text-align: start;
  padding-left: 25px;
}

th {
  text-align: center;
}

.hide {
  box-shadow: none;

  img {
    transform: rotate(180deg);
  }
}

.expander {
  display: grid;
  justify-content: center;
  box-shadow: 0px -33px 29px white;
  z-index: 1;
  position: relative;
  top: 20px;

  div {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem;
    cursor: pointer;
  }
}

.hide {
  box-shadow: none;

  img {
    transform: rotate(180deg);
  }
}

.package {
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
  img {
    margin-bottom: 40px;
    width: auto;
    max-height: 166px;
  }

  h3 {
    text-transform: uppercase;
    padding-bottom: 0%;
    margin-bottom: 0%;
    min-height:72px;
  }
}

div[data-bs-toggle="modal"] {
  cursor: pointer;
}

.modal-dialog {
  top: 15%;

  .btn-close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 0.5em;
    height: 0.5em;
  }

  .modal-wrapper {
    background-color: white;
    box-shadow: 0 0 30px #000;
  }

  div.col-6:nth-child(2)>span:nth-child(2) {
    position: relative;
    bottom: 32px;
  }

  img {
    width: 93%;
  }

  .progress-wrapper:first-of-type {
    padding-top: 9%;
  }

  .col-6 {
    padding: 4% 8%;
  }
}

.modal-backdrop {
  background-color: white;
  opacity: 0.8;
}

.progress-wrapper {
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  p {
    margin-bottom: 0%;
  }
}

.progress {
  height: 6px;
  margin-bottom: 12px;

  .progress-bar {
    background-color: #fb5858;
    -webkit-transition: width 2.5s ease;
    transition: width 2.5s ease;
    width: 0%;
  }
}

.img {
  text-align: center;
}

.contact-nav {
  position: relative;
  padding-top: 80px;
}

.section-contact {
  padding-top: 40px;
  padding-bottom: 0px;

  .col-6 {
    padding: 0px;
  }

  .div-h2 {
    margin-bottom: 40px;
  }

  h2 {
    font-weight: bold;
  }
}

.contact {
  height: 100%;
  p > *{
    padding-left:35px;
  }
  p > a{
    background: url('../img/phone.jpg') no-repeat left center;
  }
  p > a.mail-link{
    background: url('../img/mail.jpg') no-repeat left center;
  }
}

.section-forms {
  margin-top: 0%;
  padding-top: 0%;
}

.section-contact {
  padding-left: 0%;
}

.place-card {
  display: none !important;
}

.modal.fade .modal-dialog{
  opacity: 0;
  transform: translate(0, 0) scale(1);
  -webkit-transition: all .4s linear;
  -o-transition: all .4 linear;
  transition: all .4 linear;
}

.modal.fade.show .modal-dialog{
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

#mapDiv>div:nth-child(1)>div:nth-child(1)>div:nth-child(5)>div:nth-child(1) {
  display: none;
}

.gm-inset-map {
  display: none;
}

#mapDiv>div:nth-child(1)>div:nth-child(1)>div:nth-child(15)>div:nth-child(1) {
  display: none;
}

.zoom {
  display: block;
  overflow: hidden;
  padding: 1% 0;

  img {
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;

    &:hover {
      -webkit-transform: scale(1.04);
      -ms-transform: scale(1.04);
      transform: scale(1.04);
    }
  }
}

.section-contact {
  .zoom {
    padding: 0;
  }
}

.modal-dialog {
  max-width: inherit;
}
.embed-responsive {
  video {
    max-width: 100%;
    height: auto;
  }
}

// @media(max-width: 350px) {
//   .slick-slide{
//     .btn{
//       height: 80px;
//     }
//   }
// }
@media(max-width: 991px) {
  .about-statistics {
    .description {
      font-size: 18px;
    }

    .numbers {
      line-height: 24px;
    }
  }

  .banner-top {
    h1 {
      font-size: 3.6rem;
    }
  }

  .quality-section {
    .zoom {
      img {
        max-height: 345px;
      }
    }
  }
}

@media(max-width: 767px) {
  .banner-top {
    h1 {
      font-size: 3.3rem;
    }
  }

  .div-h2 {
    margin-bottom: 40px;
  }

  .quality-logos {
    padding-bottom: 40px;
  }
}

@media(max-width: 575px) {
  .banner-top {
    h1 {
      font-size: 2.8rem;
    }
  }
}

@media(max-width: 395px) {
  .banner-top {
    h1 {
      font-size: 2.3rem;
    }
  }

}

@media (min-width: 576px) {
  .component {
    padding: 0 5px;
  }

  .all-fruits {
    .apple-img {
      img {
        width: 80%;
        max-width: 254px;
      }
    }

    h3 {
      padding-bottom: 0px;
      margin-bottom: 8px;
    }
  }

  .sec-banner-text {
    width: 70%;
  }

  .sec-banner-wrapper {
    min-height: 87%;
    padding-left: 35px;
  }
}

@media(max-width: 767px) {

  .modal-dialog {
    img {
      width: 50%;
    }

  }

  .sec-banner-text{
    width: 100%;
  }
  .sec-banner{
    height: 85vh;
  }
}
@media(max-width: 500px) {
  .banner-top .slide-img .desktop {
    display: none;
  }
  .banner-top .slide-img .mobile {
    display: inline;
  }
}

.contact {
  text-align: center;
}

@media(min-width: 768px) {
  .modal-wrapper {
    padding: 10%;
  }

  .modal-img {
    text-align: center;

    img {
      margin: auto;
      width: 360px;
    }
  }

  .all-fruits {
    .apple-img {
      img {
        width: 100%;
      }
    }

    h3 {
      margin-bottom: 8px;
      min-height: 72px;
    }
  }

  .package {
    margin-bottom: 75px;
  }
}

@media(max-width: 992px) {
  .img {
    margin-top: 5%;
  }

  .about-statistics {
    .numbers {
      font-size: 2.6rem;
    }
  }

  .section-content {
    .btn {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .swiper-slide img{
    max-height: 200px;
  }
}

@media (min-width: 992px) {
  .sec-banner {
    margin-bottom: 80px;
  }

  .numbers {
    font-size: 3rem;
  }

  .sec-banner-text {
    width: 55%;
  }

  .text-left {
    padding-right: 10%;
    height: 100%;
  }

  .logo-wrapper {
    justify-content: space-between !important;
  }

  .text-right {
    position: relative;
    left: 50%;
    padding-left: 10%;
  }


  .img-left {
    position: absolute;
    top: 0;
    left: 0%;
    height: 100%;
    overflow: visible;

    div {
      height: 100%;
      overflow: visible;
    }

    img {
      max-height: 100%;
      width:auto;
      overflow: visible;
    }
  }

  .img-right {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: visible;

    div {
      height: 100%;
    }

    img {
      max-height: 100%;
      width:auto;
    }
  }

  .half-width {
    img {
      width: 100%;
    }
  }

  .section-content {
    position: relative;
  }

  .logo-side-wrapper {
    height: 100%;


    div {
      height: 50%;

    }

    img {
      height: 100%;
      width:auto;
    }
  }

  .slick-slide {
    .btn {
      display: none;
    }
  }
}


@media(max-width: 1199px) {
  .img-statistics {
    display: flex;
    align-items: center;
  }


  // .sec-banner-text {
  //   width: 45%;
  // }
}

@media(min-width: 1200px) {
  .sec-banner .sec-banner-wrapper {
    background: linear-gradient(90deg, rgba(255, 255, 255, .92), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, .5), rgba(255, 255, 255, .1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  }
  .package {
    margin-bottom: 100px;
  }
  .img-statistics {
    transform: scale(.65);
  }
}

// @media (min-width: 1200px) {

// }

@media (min-width: 1400px) {
  .sec-banner-text {
    width: 46%;
  }

  .numbers {
    font-size: 3.2rem;
  }

}