/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide.active {
    display: block !important;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    text-align: center;
    margin: 0px 10px;



    [dir="rtl"] & {
        float: right;
    }



    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

}

.slick-slide.slick-current {
    .btn {
        display: inline-block;
    }
}

.slick-slide.slick-active {
    visibility: visible;



}

.slick-arrow.slick-hidden {
    display: none;
}

.slick .slick-next {
    background: url(../img/arrow_right.png) no-repeat center;
    top: 40%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0px;
}

.slick .slick-next:hover {
    right: -4px;
}

.slick .slick-arrow {
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    z-index: 100;
    border: none;
    text-indent: -9999px;
    display: block;
    position: absolute;
    cursor: pointer;
    width: 35px;
    height: 45px;
    top: 50%;
    z-index: 1;
}

.slick .slick-prev {
    background: url(../img/arrow_left.png) no-repeat center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0px;
}

.slick .slick-prev:hover {
    left: -4px;
}

.slick-list {
    padding: 30px 20px !important;
}

.slick-current {


    .btn {
        display: inline-block;
    }
}

.product-description {
    max-width: 420px;
    margin:auto;
    h3 {
        text-transform: uppercase;
    }
}

@media(min-width: 768px) {
    // .slick-active {
    //     .product-image {
    //         padding: 0%;
    //     }
    // }

    // .product-image {
    //     padding: 0% 12%;
    // }

    .slick .slick-arrow {
        top: 50%;
    }
}

@media(max-width: 992px) {
    .slick-slide {
        img {
            max-height: 294px;
        }
    }

}

@media(min-width: 992px) {


    .slick .slick-prev {
        left: -33px;

        &:hover {
            left: -38px;
        }
    }

    .slick .slick-next {
        right: -33px;

        &:hover {
            right: -38px;
        }
    }

    .slick-slide {
        transition: .1s;
        position: relative;
        bottom: 0px;
        color: transparent;
    }



    .slick-slide.slick-current {
        position: relative;
        bottom: 17px;
        color: black;

        img {
            scale: 1.2;
        }


    }
}

@media(min-width: 1200px) {

}

@media(min-width: 1400px) {
    .slick-list {
        padding: 30px 40px !important;
    }
}