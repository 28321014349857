/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide.active {
  display: block !important;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  text-align: center;
  margin: 0px 10px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-slide.slick-current .btn {
  display: inline-block;
}

.slick-slide.slick-active {
  visibility: visible;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick .slick-next {
  background: url(../img/arrow_right.png) no-repeat center;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
}

.slick .slick-next:hover {
  right: -4px;
}

.slick .slick-arrow {
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  z-index: 100;
  border: none;
  text-indent: -9999px;
  display: block;
  position: absolute;
  cursor: pointer;
  width: 35px;
  height: 45px;
  top: 50%;
  z-index: 1;
}

.slick .slick-prev {
  background: url(../img/arrow_left.png) no-repeat center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
}

.slick .slick-prev:hover {
  left: -4px;
}

.slick-list {
  padding: 30px 20px !important;
}

.slick-current .btn {
  display: inline-block;
}

.product-description {
  max-width: 420px;
  margin: auto;
}

.product-description h3 {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .slick .slick-arrow {
    top: 50%;
  }
}

@media (max-width: 992px) {
  .slick-slide img {
    max-height: 294px;
  }
}

@media (min-width: 992px) {
  .slick .slick-prev {
    left: -33px;
  }
  .slick .slick-prev:hover {
    left: -38px;
  }
  .slick .slick-next {
    right: -33px;
  }
  .slick .slick-next:hover {
    right: -38px;
  }
  .slick-slide {
    transition: .1s;
    position: relative;
    bottom: 0px;
    color: transparent;
  }
  .slick-slide.slick-current {
    position: relative;
    bottom: 17px;
    color: black;
  }
  .slick-slide.slick-current img {
    scale: 1.2;
  }
}

@media (min-width: 1400px) {
  .slick-list {
    padding: 30px 40px !important;
  }
}

.bg-gray {
  background-color: #f9f9f9;
}

.uppercase {
  text-transform: uppercase;
}

.form-control {
  color: black;
  border: none;
  height: 38px;
  margin-bottom: 0;
  top: 3px;
  border-radius: 0;
  background-color: #e5e5e5;
  transition: 0.5s;
  font-weight: 500;
  width: 100%;
  font-family: "poppins";
}

.form-control:focus {
  box-shadow: none;
  outline: none;
  background-color: #e5e5e5;
}

.form-control.search-input {
  padding-left: 35px;
  background: url(../img/search.svg) no-repeat left center;
}

.form-control::-webkit-input-placeholder {
  color: #aaaaaa !important;
  opacity: 1;
  font-size: 18px;
  line-height: 38px;
  font-weight: 100;
}

.form-control::-ms-input-placeholder {
  color: #aaaaaa !important;
  opacity: 1;
  font-size: 18px;
  line-height: 38px;
  font-weight: 100;
}

.form-control::placeholder {
  color: #aaaaaa !important;
  opacity: 1;
  font-size: 18px;
  line-height: 38px;
  font-weight: 100;
}

textarea.form-control {
  min-height: 130px;
}

.custom-control {
  position: relative;
  left: 4px;
  line-height: 16px;
}

textarea {
  resize: none;
}

input[type=checkbox] ~ label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  position: relative;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox] ~ label:before {
  content: "\2714";
  left: -1.8rem;
  top: 4px;
  position: absolute;
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 22px;
  height: 22px;
  padding-left: 3.5px;
  padding-top: 3px;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: .2s;
}

input[type=checkbox] ~ label:active:before {
  transform: scale(0);
}

input[type=checkbox]:checked ~ label:before {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  color: #fff;
}

input[type=checkbox]:disabled ~ label:before {
  transform: scale(1);
  border-color: #aaaaaa;
}

input[type=checkbox]:checked:disabled ~ label:before {
  transform: scale(1);
  background-color: #aaaaaa;
  border-color: #aaaaaa;
}

@font-face {
  font-family: "Louis George Cafe";
  src: url("./fonts");
  src: url("./fonts/Louis George Cafe.ttf") format("ttf"), url("./fonts/Louis George Cafe.ttf") format("truetype");
}

@font-face {
  font-family: "nunito";
  src: url("./fonts");
  src: url("./fonts/Nunito-VariableFont_wght.ttf") format("ttf"), url("./fonts/Nunito-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "poppins";
  src: url("./fonts");
  src: url("./fonts/Poppins-Regular.ttf") format("ttf"), url("./fonts/Poppins-Regular.ttf") format("truetype");
}

body {
  font-family: "nunito";
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  transition: 0.6s;
  text-decoration: underline;
  text-decoration-color: transparent;
}

a {
  color: black;
  font-family: "poppins";
  text-decoration: none;
  text-decoration: transparent;
  -webkit-transition: .6s ease-out;
  -moz-transition: .6s ease-out;
  -o-transition: .6s ease-out;
  transition: .6s ease-out;
}

a:hover {
  text-decoration: black;
  text-decoration: underline;
}

a:hover {
  color: black;
}

h2 {
  text-transform: uppercase;
}

h1,
h3 {
  font-size: 1.875rem;
  font-family: "nunito";
}

p,
span {
  font-size: 0.875rem;
}

.btn {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "poppins";
  position: relative;
  font-size: 12px;
  letter-spacing: 1px;
  min-width: 200px;
  text-align: center;
  border: 0px;
  transition: .6s;
  line-height: 43px;
  height: 47px;
  padding: 0 29px 0px 25px;
}

.btn span {
  font-size: 12px;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn img {
  position: absolute;
  width: calc(100% + 4px);
  height: 40px;
  top: 0px;
  left: 0px;
}

.button-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.button-bg:hover {
  transition: .6s;
  text-decoration: none;
}

.btn-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../img/Dowiedz_sie_wiecej_button_in_color.jpg");
}

button.btn img {
  height: 43px;
}

.languages {
  font-size: 14px;
  border-color: black !important;
}

.border-top {
  border-top: 1px solid #000 !important;
}

.component {
  display: inline-block;
  padding: 0 2px;
}

.component:first-child {
  padding-left: 0;
}

.component a {
  text-decoration: none;
}

.component img {
  width: 24px;
  height: 24px;
}

header {
  position: fixed;
  z-index: 100;
  background: white;
  width: 100vw;
}

.nav {
  padding: 0;
}

.navbar {
  height: 79px;
  background-color: white !important;
  padding: 0;
}

.navbar > div {
  height: 100%;
}

.navbar .navbar-links-wrapper {
  display: flex;
  justify-content: center;
}

.navbar .navbar-links-wrapper ul {
  display: inline-block;
  margin-right: 7.1%;
  margin-left: auto;
}

.navbar li {
  display: inline-block;
  text-transform: uppercase;
  padding: 0 10px;
}

.navbar li a {
  text-transform: uppercase;
  transition: 0.6s;
  font-weight: 700;
}

.navbar li a:hover, .navbar li a.active {
  text-decoration: underline;
}

.navbar .navbar-components {
  padding-right: 0px;
}

.navbar .row {
  width: 100%;
}

.navbar .navbar-collapse {
  position: absolute;
  top: 79px;
  width: 100vw;
  left: 0;
  background-color: #f2f2f2;
}

.navbar .navbar-toggler {
  padding: 0;
  border: none;
  position: relative;
  top: 1px;
}

.navbar .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar .navbar-toggler span {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  display: inline-block;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  content: "";
  background-size: auto;
  background-size: 100% 100%;
}

.navbar .brand {
  height: 100%;
}

.prev-modal {
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.next-modal {
  padding: 5px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.slick .slick-next {
  right: 10px;
}

.slick .slick-next:hover {
  right: 3px;
}

.slick .slick-prev {
  left: 10px;
}

.slick .slick-prev:hover {
  left: 3px;
}

.banner-top {
  height: 65vh;
  top: 79px;
  z-index: 0;
  padding: 0%;
}

.banner-top .banner-text {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  text-align: center;
}

.banner-top h1 {
  z-index: 1;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 4.4rem;
  color: white;
  text-shadow: 0px 0px 35px #000, 0px 0px 35px #000;
}

.banner-top .flex-control-paging li a {
  box-shadow: none;
  width: 17px;
  height: 17px;
  border: 5px solid white;
  background: none;
}

.banner-top .flex-control-nav {
  bottom: 8%;
  z-index: 2;
}

.banner-top .flex-control-paging li a.flex-active {
  background: white;
}

.banner-top .flexslider {
  height: 100%;
  overflow: hidden;
  border: none;
}

.banner-top .slides {
  height: 100%;
}

.banner-top li {
  height: 100%;
}

.banner-top .slide-img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 100;
  top: 0;
}

.banner-top .slide-img video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-top .slide-img .mobile {
  display: none;
}

.about {
  margin-top: 79px;
}

section {
  padding: 60px 0px;
}

.div-h2 {
  margin-bottom: 50px;
}

.div-h2 h2 {
  border-bottom: 1px solid #F90101;
  padding-bottom: 5px;
  font-weight: 700;
}

h1,
h3 {
  padding-bottom: 20px;
}

.youtube-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 56.25%;
}

.youtube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.statistics {
  padding-top: 50px;
}

.statistics .statistics-img {
  width: 50%;
}

.statistics div {
  text-align: center;
}

.statistics .description {
  padding-top: 24px;
  font-size: 24px;
}

.numbers {
  font-size: 3.2rem;
  line-height: 31px;
  color: #80c747;
  font-family: "Louis George Cafe";
}

.numbers span {
  font-size: inherit;
}

.logo-side-wrapper div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logos-index .logos {
  justify-content: center;
}

.logos-index div {
  display: flex;
  align-items: center;
  padding: 20px 20px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  height: 100%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide a {
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: auto;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next {
  display: none;
}

.swiper-button-prev {
  display: none;
}

.checked.swiper .swiper-slide {
  margin-top: auto;
  margin-bottom: 0;
}

footer {
  background-color: #aaaaaa;
}

footer h5 {
  font-size: 20px;
}

footer li {
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  font-family: 'poppins';
  margin-bottom: 3.5px;
}

footer li a {
  font-size: 14px;
}

.social .component {
  display: inline-block;
}

.sec-banner {
  position: relative;
  top: 79px;
  display: flex;
  align-items: center;
  margin-bottom: 70px;
  overflow: hidden;
  height: 65vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sec-banner .sec-banner-wrapper {
  background: linear-gradient(90deg, white, rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.05));
  min-height: 70%;
  display: flex;
  align-items: center;
}

.sec-banner .sec-banner-wrapper .row {
  align-items: center;
  height: 100%;
  width: 100%;
}

.sec-banner .sec-banner-wrapper h2 {
  padding-bottom: 20px;
}

.sec-banner-text {
  line-height: 16px;
  width: 85%;
}

.about-statistics .col {
  padding: 10px 0;
}

.about-statistics .numbers {
  line-height: 55px;
  margin-bottom: 8px;
}

.statistics-description {
  align-content: center;
  display: grid;
}

.statistics-description .description {
  font-size: 20px;
}

.map {
  text-align: center;
}

.img-statistics {
  position: relative;
  bottom: 5px;
}

.table-responsive {
  padding-bottom: 20px;
  height: 320px;
  overflow-Y: hidden;
  z-index: 1;
  transition: .5s ease-in;
  width: 100%;
}

.all-fruits .apple-img img {
  width: 50%;
  max-width: 150px;
}

.all-fruits h3 {
  text-transform: uppercase;
  padding-bottom: 0%;
  margin-bottom: 0%;
  min-height: 72px;
}

table {
  z-index: 0;
  width: 100%;
}

tr {
  height: 60px;
}

tr:first-of-type {
  height: 80px;
}

td {
  width: 90px;
  text-align: center;
  border-bottom: 3px solid #d3d3d3;
  min-height: 60px;
}

th {
  height: 30px;
  text-transform: uppercase;
  font-size: 0.75rem;
  min-width: 90px;
}

td:nth-child(2n) {
  background-color: #e8e8e8;
}

th:nth-child(2n) {
  background-color: #dadada;
}

td:nth-child(2n + 1) {
  background-color: #eeeeee;
}

th:nth-child(2n + 1) {
  background-color: #dfdfdf;
}

th:first-of-type {
  background-color: #dcdcdc;
  min-width: 136px;
  font-weight: bold;
}

td:first-of-type {
  background-color: #dcdcdc;
  font-weight: bold;
  text-align: start;
  padding-left: 25px;
}

th {
  text-align: center;
}

.hide {
  box-shadow: none;
}

.hide img {
  transform: rotate(180deg);
}

.expander {
  display: grid;
  justify-content: center;
  box-shadow: 0px -33px 29px white;
  z-index: 1;
  position: relative;
  top: 20px;
}

.expander div {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
  cursor: pointer;
}

.hide {
  box-shadow: none;
}

.hide img {
  transform: rotate(180deg);
}

.package {
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
}

.package img {
  margin-bottom: 40px;
  width: auto;
  max-height: 166px;
}

.package h3 {
  text-transform: uppercase;
  padding-bottom: 0%;
  margin-bottom: 0%;
  min-height: 72px;
}

div[data-bs-toggle="modal"] {
  cursor: pointer;
}

.modal-dialog {
  top: 15%;
}

.modal-dialog .btn-close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 0.5em;
  height: 0.5em;
}

.modal-dialog .modal-wrapper {
  background-color: white;
  box-shadow: 0 0 30px #000;
}

.modal-dialog div.col-6:nth-child(2) > span:nth-child(2) {
  position: relative;
  bottom: 32px;
}

.modal-dialog img {
  width: 93%;
}

.modal-dialog .progress-wrapper:first-of-type {
  padding-top: 9%;
}

.modal-dialog .col-6 {
  padding: 4% 8%;
}

.modal-backdrop {
  background-color: white;
  opacity: 0.8;
}

.progress-wrapper {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.progress-wrapper p {
  margin-bottom: 0%;
}

.progress {
  height: 6px;
  margin-bottom: 12px;
}

.progress .progress-bar {
  background-color: #fb5858;
  -webkit-transition: width 2.5s ease;
  transition: width 2.5s ease;
  width: 0%;
}

.img {
  text-align: center;
}

.contact-nav {
  position: relative;
  padding-top: 80px;
}

.section-contact {
  padding-top: 40px;
  padding-bottom: 0px;
}

.section-contact .col-6 {
  padding: 0px;
}

.section-contact .div-h2 {
  margin-bottom: 40px;
}

.section-contact h2 {
  font-weight: bold;
}

.contact {
  height: 100%;
}

.contact p > * {
  padding-left: 35px;
}

.contact p > a {
  background: url("../img/phone.jpg") no-repeat left center;
}

.contact p > a.mail-link {
  background: url("../img/mail.jpg") no-repeat left center;
}

.section-forms {
  margin-top: 0%;
  padding-top: 0%;
}

.section-contact {
  padding-left: 0%;
}

.place-card {
  display: none !important;
}

.modal.fade .modal-dialog {
  opacity: 0;
  transform: translate(0, 0) scale(1);
  -webkit-transition: all .4s linear;
  -o-transition: all .4 linear;
  transition: all .4 linear;
}

.modal.fade.show .modal-dialog {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

#mapDiv > div:nth-child(1) > div:nth-child(1) > div:nth-child(5) > div:nth-child(1) {
  display: none;
}

.gm-inset-map {
  display: none;
}

#mapDiv > div:nth-child(1) > div:nth-child(1) > div:nth-child(15) > div:nth-child(1) {
  display: none;
}

.zoom {
  display: block;
  overflow: hidden;
  padding: 1% 0;
}

.zoom img {
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: .8s;
  -o-transition: .8s;
  transition: .8s;
}

.zoom img:hover {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}

.section-contact .zoom {
  padding: 0;
}

.modal-dialog {
  max-width: inherit;
}

.embed-responsive video {
  max-width: 100%;
  height: auto;
}

@media (max-width: 991px) {
  .about-statistics .description {
    font-size: 18px;
  }
  .about-statistics .numbers {
    line-height: 24px;
  }
  .banner-top h1 {
    font-size: 3.6rem;
  }
  .quality-section .zoom img {
    max-height: 345px;
  }
}

@media (max-width: 767px) {
  .banner-top h1 {
    font-size: 3.3rem;
  }
  .div-h2 {
    margin-bottom: 40px;
  }
  .quality-logos {
    padding-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .banner-top h1 {
    font-size: 2.8rem;
  }
}

@media (max-width: 395px) {
  .banner-top h1 {
    font-size: 2.3rem;
  }
}

@media (min-width: 576px) {
  .component {
    padding: 0 5px;
  }
  .all-fruits .apple-img img {
    width: 80%;
    max-width: 254px;
  }
  .all-fruits h3 {
    padding-bottom: 0px;
    margin-bottom: 8px;
  }
  .sec-banner-text {
    width: 70%;
  }
  .sec-banner-wrapper {
    min-height: 87%;
    padding-left: 35px;
  }
}

@media (max-width: 767px) {
  .modal-dialog img {
    width: 50%;
  }
  .sec-banner-text {
    width: 100%;
  }
  .sec-banner {
    height: 85vh;
  }
}

@media (max-width: 500px) {
  .banner-top .slide-img .desktop {
    display: none;
  }
  .banner-top .slide-img .mobile {
    display: inline;
  }
}

.contact {
  text-align: center;
}

@media (min-width: 768px) {
  .modal-wrapper {
    padding: 10%;
  }
  .modal-img {
    text-align: center;
  }
  .modal-img img {
    margin: auto;
    width: 360px;
  }
  .all-fruits .apple-img img {
    width: 100%;
  }
  .all-fruits h3 {
    margin-bottom: 8px;
    min-height: 72px;
  }
  .package {
    margin-bottom: 75px;
  }
}

@media (max-width: 992px) {
  .img {
    margin-top: 5%;
  }
  .about-statistics .numbers {
    font-size: 2.6rem;
  }
  .section-content .btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-slide img {
    max-height: 200px;
  }
}

@media (min-width: 992px) {
  .sec-banner {
    margin-bottom: 80px;
  }
  .numbers {
    font-size: 3rem;
  }
  .sec-banner-text {
    width: 55%;
  }
  .text-left {
    padding-right: 10%;
    height: 100%;
  }
  .logo-wrapper {
    justify-content: space-between !important;
  }
  .text-right {
    position: relative;
    left: 50%;
    padding-left: 10%;
  }
  .img-left {
    position: absolute;
    top: 0;
    left: 0%;
    height: 100%;
    overflow: visible;
  }
  .img-left div {
    height: 100%;
    overflow: visible;
  }
  .img-left img {
    max-height: 100%;
    width: auto;
    overflow: visible;
  }
  .img-right {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: visible;
  }
  .img-right div {
    height: 100%;
  }
  .img-right img {
    max-height: 100%;
    width: auto;
  }
  .half-width img {
    width: 100%;
  }
  .section-content {
    position: relative;
  }
  .logo-side-wrapper {
    height: 100%;
  }
  .logo-side-wrapper div {
    height: 50%;
  }
  .logo-side-wrapper img {
    height: 100%;
    width: auto;
  }
  .slick-slide .btn {
    display: none;
  }
}

@media (max-width: 1199px) {
  .img-statistics {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .sec-banner .sec-banner-wrapper {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  }
  .package {
    margin-bottom: 100px;
  }
  .img-statistics {
    transform: scale(0.65);
  }
}

@media (min-width: 1400px) {
  .sec-banner-text {
    width: 46%;
  }
  .numbers {
    font-size: 3.2rem;
  }
}
