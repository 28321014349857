// $color-white: #FFFFFF;
// $color-black: #0A0A0A;
// $primary-gray: #707070;
// $secondary-gray: #696969;
// $tertiary-gray: #A3A3A3;
// $quaternary-gray: #F7F7F7;
// $color-primary: #2A838C;
// $color-secondary: #0618FF;
// $color-tertiary: #0A7F02;
// $breadcrumb-divider: quote(">");
// $navbar-height: 71px;

// @mixin placeholder {
//     ::-webkit-input-placeholder {@content}
//     :-moz-placeholder           {@content}
//     ::-moz-placeholder          {@content}
//     :-ms-input-placeholder      {@content}  
//   }
$navbar-height: 79px;
$navbar-bg: #f2f2f2;
$secondary-color: #80c747;
$primary-gray: #f9f9f9;
$secondary-gray: #aaaaaa;
$tertiary-gray: #e5e5e5;
$modal-fade-transform:              translate(0, 0);
$modal-scale-transform:             scale(1);
.bg-gray {
    background-color: $primary-gray;
}

.uppercase {
    text-transform: uppercase;
}

$td-2n: #e8e8e8;
$td-2n1: #eeeeee;
$th-2n: #dadada;
$th-2n1: #dfdfdf;
$td: #dcdcdc;
$th-first: #dcdcdc;