.form-control {
  color: black;
  border: none;
  height: 38px;
  margin-bottom: 0;
  top: 3px;
  border-radius: 0;
  background-color: $tertiary-gray;
  transition: 0.5s;
  font-weight: 500;
  width: 100%;
  font-family: "poppins";

  &:focus {
    box-shadow: none;
    outline: none;
    background-color: $tertiary-gray;
  }

  &.search-input {
    padding-left: 35px;
    background: url(../img/search.svg) no-repeat left center;
  }

  &::-webkit-input-placeholder {
    color: $secondary-gray !important;
    opacity: 1;
    font-size: 18px;
    line-height: 38px;
    font-weight: 100;
  }

  &::-ms-input-placeholder {
    color: $secondary-gray !important;
    opacity: 1;
    font-size: 18px;
    line-height: 38px;
    font-weight: 100;
  }

  &::placeholder {
    color: $secondary-gray !important;
    opacity: 1;
    font-size: 18px;
    line-height: 38px;
    font-weight: 100;
  }
}

textarea.form-control {
  min-height: 130px;

}

.custom-control {
  position: relative;
  left: 4px;
  line-height: 16px;

}

textarea {
  resize: none;
}

input[type=checkbox]~label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  position: relative;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox]~label:before {
  content: "\2714";
  left: -1.8rem;
  top: 4px;
  position: absolute;
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 22px;
  height: 22px;
  padding-left: 3.5px;
  padding-top: 3px;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  transition: .2s;
}

input[type=checkbox]~label:active:before {
  transform: scale(0);
}

input[type=checkbox]:checked~label:before {
  background-color: $secondary-gray;
  border-color: $secondary-gray;
  color: #fff;
}

input[type=checkbox]:disabled~label:before {
  transform: scale(1);
  border-color: $secondary-gray;
}

input[type=checkbox]:checked:disabled~label:before {
  transform: scale(1);
  background-color: $secondary-gray;
  border-color: $secondary-gray;
}